<template>
  <div class="pa-2">
    <CustomDatatable
      id="table-validation-cotation"
      :isPaginated="true"
      :columns="columns"
      :sortKey="sortKey"
      :sortOrders="sortOrders"
      @sort="sortBy"
      @search="rechercheData"
      @refreshData="refreshData($event)"
      @rechercheData="rechercheData($event)"
      :paginationObjectProps="paginationObject"
    >
      <template v-slot:body>
        <tbody
          style="vertical-align: middle"
          class="text-center"
          v-if="listeCotations?.length > 0"
        >
          <tr v-for="(element, index) in listeCotations" :key="element.id">
            <td>{{ element.date }}</td>
            <td>
              <v-btn
                width="90%"
                :color="$colors[0]"
                class="pa-4"
                outlined
                @click="openDialogChangeCommercial(element)"
              >
                <span>{{ element.Commerciale }}</span>
              </v-btn>
            </td>
            <td>
              <div class="d-flex flex-column">
                <div v-if="element.Raison.length > 15">
                  <v-tooltip :color="$colors[0]" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{
                        element.Raison.substring(0, 15) + "..."
                      }}</span>
                    </template>
                    <span class="Poppins-Bold" style="font-size: smaller">{{
                      element.Raison
                    }}</span>
                  </v-tooltip>
                </div>
                <span v-else>{{ element.Raison }}</span>
                <span class="Poppins-Bold" style="font-size: small">{{
                  element.siret
                }}</span>
              </div>
            </td>
            <td>
              <span v-if="element.typeDemande == 'ELEC'"
                ><v-icon large color="yellow darken-2"
                  >mdi-lightning-bolt-outline</v-icon
                ></span
              >
              <span v-if="element.typeDemande == 'GAZ'"
                ><v-icon large color="orange darken-4">mdi-fire</v-icon></span
              >
            </td>
            <td class="text-center">
              <div style="max-width: 200px; position: relative">
                <small class="Poppins-Bold">{{
                  spliteList(element.numCompteur)[0]
                }}</small>
                <v-icon
                  class="ml-0"
                  style="position: absolute"
                  v-if="spliteListSlice(element.numCompteur)?.length > 0"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#multiCollapseCompteur' + index"
                  aria-expanded="false"
                  :aria-controls="'multiCollapseCompteur' + index"
                  right
                >
                  mdi-menu-down
                </v-icon>
                <div
                  class="collapse multi-collapse"
                  :id="'multiCollapseCompteur' + index"
                >
                  <div>
                    <small
                      class="Poppins-Bold"
                      v-for="c in spliteListSlice(element.numCompteur)"
                    >
                      {{ c }}
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column">
                <div
                  class="ml-1"
                  v-if="!!element[typeFichier][0]"
                  v-for="typeFichier in tableauFichiers"
                >
                  <small class="dropend" v-if="!!element[typeFichier][0]">
                    <v-btn
                      text
                      small
                      :color="$colors[0]"
                      retain-focus-on-click
                      class="dropdown-toggle buttonStyle mb-1"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span v-if="typeFichier == 'fileUrl'">Pièce Jointe</span>
                      <span v-else>{{ typeFichier }}</span>
                    </v-btn>
                    <ul
                      :style="{ backgroundColor: $colors[0] }"
                      class="dropdown-menu dropdown-menu-start pa-0"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        class="d-flex flex-column justify-center"
                        v-for="(fichier, index) in element[typeFichier]"
                      >
                        <a
                          class="dropdown-item rounded-lg"
                          style="width: 100%"
                          :href="fichier"
                        >
                          <v-icon dense color="white" class="mr-2"
                            >mdi-file-download-outline</v-icon
                          >
                          <span
                            v-if="typeFichier == 'fileUrl'"
                            class="white--text dropdown-span"
                            :style="{ color: $colors[0] }"
                            style="font-size: smaller"
                            >PIÈCE JOINTE {{ ++index }}</span
                          >
                          <span
                            v-else
                            class="white--text dropdown-span"
                            :style="{ color: $colors[0] }"
                            style="font-size: smaller"
                            >{{ typeFichier.toUpperCase() }} {{ ++index }}</span
                          >
                        </a>
                        <div
                          style="width: 100%; border: 0.5px solid white"
                          v-if="!!element[typeFichier][index]"
                        ></div>
                      </li>
                    </ul>
                  </small>
                </div>
              </div>
            </td>
            <td>{{ element.Echeance }}</td>
            <td class="text-center">
              <div class="d-flex flex-column btn-group">
                <div class="d-flex flex-row justify-lg-start justify-xl-center">
                  <small class="small-status" v-if="element.valide == '4'"
                    ><v-icon dense color="red darken-1">mdi-close-circle-outline</v-icon
                    ><b class="ml-2 Poppins-Bold">annulée</b></small
                  >
                  <small class="small-status" v-if="element.valide == '3'"
                    ><v-icon dense style="color: orange">mdi-alert-outline</v-icon
                    ><b class="ml-2 Poppins-Bold">ACD expiré</b></small
                  >
                  <small class="small-status" v-if="element.valide == '2'"
                    ><v-icon dense style="color: red">mdi-close-circle-outline</v-icon
                    ><b class="ml-2 Poppins-Bold">refusée</b></small
                  >
                  <small class="small-status" v-if="element.valide == '1'"
                    ><v-icon dense style="color: #00e676">mdi-check-circle-outline</v-icon
                    ><b class="ml-2 Poppins-Bold">acceptée</b></small
                  >
                  <small class="small-status" v-if="element.valide == '0'"
                    ><v-icon dense style="color: gray">mdi-timer-refresh-outline</v-icon
                    ><b class="ml-2 Poppins-Bold">en attente</b></small
                  >
                  <div class="d-flex flex-row">
                    <v-icon
                      style="position: absolute"
                      class="dropdown-toggle"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#collapseStatut' + index"
                      aria-expanded="false"
                      :aria-controls="'collapseStatut' + index"
                      size="20"
                      left
                    >
                      mdi-menu-down
                    </v-icon>
                  </div>
                </div>
                <div class="collapse" :id="'collapseStatut' + index" style="border: 0px">
                  <div class="d-flex flex-column mt-4 mt-lg-2">
                    <v-btn
                      @click="changementStatutCotation(element, 'acd_expire')"
                      v-if="element.valide != '3'"
                      class="dropdown-element"
                      small
                      color="orange"
                      outlined
                    >
                      <v-icon dense class="mr-2">mdi-alert-outline</v-icon>
                      <span class="Poppins-Bold">ACD EXPIRÉ</span>
                    </v-btn>
                    <v-btn
                      @click="changementStatutCotation(element, 'annuler')"
                      class="dropdown-element mt-2"
                      v-if="element.valide != '4'"
                      small
                      outlined
                      color="error"
                    >
                      <v-icon dense class="mr-2">mdi-close-circle-outline</v-icon>
                      <span class="Poppins-Bold">ANNULER</span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </CustomDatatable>

    <div v-if="dialogChangeCommercial">
      <v-dialog v-model="dialogChangeCommercial" width="700">
        <v-card class>
          <v-toolbar :color="$colors[0]" class="text-white" dark>
            <span class="Poppins-Bold pl-0">Changement Vendeur Cotation</span>
          </v-toolbar>
          <v-card-text class="pa-4 mt-4">
            <v-text-field
              outlined
              dense
              disabled
              label="Vendeur Actuel"
              v-model="cotation.Commerciale"
            ></v-text-field>
            <v-select
              dense
              outlined
              label="Nouveau Vendeur"
              v-model="nouveauVendeur"
              :items="listeVendeurs"
              return-object
              item-text="username"
            ></v-select>
            <v-switch
              v-model="changeVendeurVentesAssocie"
              :label="`Appliquer le nouveau vendeur sur les ventes associées à cette cotation : ${
                changeVendeurVentesAssocie ? 'Oui' : 'Non'
              }`"
            ></v-switch>
          </v-card-text>

          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn :color="$colors[0]" class="white--text" @click="changementVendeur()">
              Changer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <Snackbar
      :model="snackbarAPI.model"
      :message="snackbarAPI.message"
      :color="snackbarAPI.color"
    />
    <Loading
      class="pa-0"
      :dialogProps="dialogLoadingCotation"
      :messageDialogeProps="messageErrorCotation"
      :loadingProps="loadingCotation"
      :errorProps="errorCotation"
      @closeDialog="closeDialog($event)"
    />
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import CustomDatatable from "@/components/datatable/CustomDatatable.vue";
import Pagination from "@/components/datatable/Pagination.vue";
import Snackbar from "@/components/Snackbar.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "CotationsView",
  components: {
    CustomDatatable,
    Pagination,
    Snackbar,
    Loading,
  },
  data() {
    return {
      errorCotation: false,
      loadingCotation: false,
      messageErrorCotation: "",
      dialogLoadingCotation: false,
      tableauFichiers: ["fileUrl", "ACD", "Facture", "Contrat"],
      snackbarAPI: {
        message: "",
        color: "",
        model: false,
      },
      expand: false,
      cotation: "",
      listeCotations: [],
      nouveauStatutCotation: "",
      listeVendeurs: [],
      nouveauVendeur: "",

      changeVendeurVentesAssocie: false,
      dialogChangeCommercial: false,

      paginationObject: {},
      sortKey: "",
      sortOrders: "",
      sortBy: "",
      paginationVisible: true,

      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      columns: [
        { width: "10vw", label: "Date d'ajout", name: "date", sortable: true },
        { width: "10vw", label: "Commercial", name: "Compte", sortable: true },
        { width: "10vw", label: "Raison Sociale", name: "Raison", sortable: true },
        { width: "10vw", label: "Energie", name: "typeDemande", sortable: true },
        { width: "10vw", label: "Compteur", name: "numCompteur", sortable: false },
        { width: "10vw", label: "Pièce jointe", sortable: false },
        { width: "10vw", label: "Début de contrat", name: "dateDebut", sortable: true },
        { width: "10vw", label: "Statut", name: "statut", sortable: true },
      ],
    };
  },
  async mounted() {
    this.dialogLoadingCotation = true;
    this.loadingCotation = true;
    await this.getAllDemandeCotation();
    await this.getAllVendeurs();
    this.loadingCotation = false;
    this.dialogLoadingCotation = false;
  },
  methods: {
    closeDialog(event) {
      this.dialogLoadingCotation = event;
      this.errorCotation = event;
    },
    spliteList(list) {
      return list.split(",");
    },
    spliteListSlice(list) {
      return list.split(",").slice(1);
    },
    openDialogChangeCommercial(objetCotation) {
      this.dialogChangeCommercial = true;
      this.cotation = objetCotation;
      this.nouveauVendeur = this.cotation.Commerciale;
      this.listeVendeurs[0] = this.nouveauVendeur;
    },
    async changementVendeur() {
      this.dialogLoadingCotation = true;
      this.loadingCotation = true;
      this.snackbarAPI.model = false;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "changeVendeurOfDemandeCotation/",
        method: "POST",
        data: {
          cotation_id: this.cotation.id,
          vendeur_id: this.nouveauVendeur.id,
          token: this.$getToken("token"),
          modify_vente_pro: this.changeVendeurVentesAssocie ? "oui" : "non",
        },
      }).then((res) => {
        if (res.data.result) {
          this.cotation.Commerciale = this.nouveauVendeur.username;
          this.snackbarAPI.color = "success";
        } else {
          this.snackbarAPI.color = "error";
        }
        this.loadingCotation = false;
        this.dialogLoadingCotation = false;
        this.snackbarAPI.message = res.data.message;
      });
      this.dialogChangeCommercial = false;
      this.snackbarAPI.model = true;
    },

    async changementStatutCotation(cotation, statutCotation) {
      this.snackbarAPI.model = false;
      if (statutCotation == "annuler") {
        cotation.valide = 4;
      } else {
        cotation.valide = 3;
      }
      this.dialogLoadingCotation = true;
      this.loadingCotation = true;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "changeDemandeCotationStatus/",
        method: "POST",
        data: {
          id: cotation.id,
          status: statutCotation,
          token: this.$getToken("token"),
        },
      })
        .then((res) => {
          if (res.data.result) {
            this.snackbarAPI.color = "success";
          } else {
            this.snackbarAPI.color = "error";
          }
          this.loadingCotation = false;
          this.dialogLoadingCotation = false;
          this.snackbarAPI.message = res.data.message;
          this.snackbarAPI.model = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.dialogLoadingCotation = true;
        this.loadingCotation = true;
        this.tableData.length = key;
      }
      this.tableData.draw++;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "listeDemandeCotation/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: this.paginationObject.current_page,
          token: this.$getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.listeCotations = data.data.data;
              this.paginationObject = data.data;
              this.loadingCotation = false;
              this.dialogLoadingCotation = false;
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async refreshData(pageNumber = 1) {
      this.dialogLoadingCotation = true;
      this.loadingCotation = true;
      this.tableData.draw++;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "listeDemandeCotation/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.listeCotations = data.data.data;
              this.paginationObject = data.data;
            }
            this.loadingCotation = false;
            this.dialogLoadingCotation = false;
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
          this.loadingCotation = false;
          this.errorCotation = true;
          this.messageErrorCotation = errors;
        });
    },
    async getAllDemandeCotation() {
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "listeDemandeCotation/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: 1,
          token: this.$getToken("token"),
        },
      })
        .then((res) => {
          this.listeCotations = res.data.data.data.data;
          this.paginationObject = res.data.data.data;
        })
        .catch((error) => {
          this.loadingCotation = false;
          this.errorCotation = true;
          this.messageErrorCotation = error;
        });
    },
    async getAllVendeurs() {
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllComptesForOptions/",
        method: "POST",
        data: {
          token: this.$getToken("token"),
        },
      }).then((res) => {
        this.listeVendeurs = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
::v-deep .buttonStyle:focus,
.buttonStyle:hover {
  color: white !important;
  background-color: rgba(1, 87, 155, 1);
}
::v-deep .dropdown-item:hover .dropdown-span {
  color: white !important;
}
::v-deep .dropdown-item {
  background-color: rgb(1, 87, 155);
}
::v-deep .dropdown-item:hover {
  opacity: 0.5;
}
::v-deep .dropdown-menu {
  min-width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
::v-deep .v-text-field--outlined.v-input--is-focused fieldset,
.v-text-field--outlined.v-input--has-state fieldset,
::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: #103a5e !important;
  color: #103a5e !important;
}

::v-deep .v-input__icon > i:before,
.v-input__icon,
::v-deep
  .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense
  .v-select__selections {
  color: #103a5e !important;
}
</style>
