<template>
  <div class="p-4">
    <CustomDatatable
      id="table-liste-ventes"
      :isPaginated="true"
      :columns="columns"
      :sortKey="sortKey"
      :sortOrders="sortOrders"
      @sort="sortBy"
      @search="rechercheData"
      @refreshData="refreshData($event)"
      @rechercheData="rechercheData($event)"
      :paginationObjectProps="paginationObject"
    >
      <template v-slot:body>
        <tbody
          class="text-center"
          v-if="listeVentes.length > 0"
          v-for="(element, index) in listeVentes"
          :key="element.id"
        >
          <tr>
            <td>{{ element.date }}</td>
            <td>
              <v-btn
                width="90%"
                :color="$colors[0]"
                class="pa-4"
                outlined
                @click="openDialogChangeCommercial(element)"
              >
                <span>{{ element.Commercial }}</span>
              </v-btn>
            </td>
            <td>
              <div class="d-flex flex-column">
                <div v-if="element.Raison.length > 15">
                  <v-tooltip :color="$colors[0]" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{
                        element.Raison.substring(0, 15) + "..."
                      }}</span>
                    </template>
                    <span class="Poppins-Bold" style="font-size: smaller">{{
                      element.Raison
                    }}</span>
                  </v-tooltip>
                </div>
                <span v-else>{{ element.Raison }}</span>
                <span class="Poppins-Bold" style="font-size: small">{{
                  element.siret
                }}</span>
              </div>
            </td>
            <td>
              <span v-if="element.type == 'ELEC'"
                ><v-icon large color="yellow darken-2"
                  >mdi-lightning-bolt-outline</v-icon
                ></span
              >
              <span v-if="element.type == 'GAZ'"
                ><v-icon large color="orange darken-4">mdi-fire</v-icon></span
              >
            </td>
            <td>{{ element.fournisseur }}</td>
            <td>
              <div class="text-center dropdown" style="max-width: 200px">
                <v-btn
                  text
                  small
                  class="dropdown-toggle"
                  :color="$colors[0]"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collaspeFiles${index}`"
                  aria-expanded="false"
                  aria-controls="collaspeFiles"
                >
                  FICHIERS
                </v-btn>
                <div
                  v-for="(key, indexVente) in Object.entries(element.ventes[0])"
                  class="collapse"
                  :id="'collaspeFiles' + index"
                >
                  <small v-if="!!key[1] && venteItems.includes(key[0])">
                    <a :href="key[1]" target="_blank">
                      <span class="text-body-4 blue--text">{{ key[0] }}</span>
                    </a>
                  </small>
                </div>
              </div>
            </td>
            <td>
              <div style="position: relative">
                <v-btn
                  style="position: absolute; right: -10px; top: -10px"
                  small
                  icon
                  color="blue darken-4"
                  v-if="element.commentaire?.length > 0"
                >
                  <v-icon @click="openDialogComment(element)"
                    >mdi-comment-eye-outline</v-icon
                  >
                </v-btn>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div>
                  <v-badge
                    color="red"
                    bottom
                    class="mr-4"
                    v-if="countStatus(element)[0] > 0"
                    offset-x="10"
                    offset-y="10"
                    :content="'' + countStatus(element)[0]"
                  >
                    <v-icon dense style="color: red">mdi-close-circle-outline</v-icon>
                  </v-badge>
                  <v-badge
                    color="#00E676"
                    bordered
                    bottom
                    class="mr-4"
                    v-if="countStatus(element)[1] > 0"
                    offset-x="10"
                    offset-y="10"
                    :content="'' + countStatus(element)[1]"
                  >
                    <v-icon dense style="color: #00e676">mdi-check-circle-outline</v-icon>
                  </v-badge>
                  <v-badge
                    color="grey"
                    bordered
                    bottom
                    class="mr-4"
                    v-if="countStatus(element)[2] > 0"
                    offset-x="10"
                    offset-y="10"
                    :content="'' + countStatus(element)[2]"
                  >
                    <v-icon dense style="color: grey">mdi-timer-refresh-outline</v-icon>
                  </v-badge>
                  <v-badge
                    :color="$colors[1]"
                    bordered
                    bottom
                    class="mr-4"
                    v-if="countStatus(element)[3] > 0"
                    offset-x="10"
                    offset-y="10"
                    :content="'' + countStatus(element)[3]"
                  >
                    <v-icon dense :color="$colors[1]">mdi-send-check-outline</v-icon>
                  </v-badge>
                </div>
              </div>
            </td>
            <td class="text-center">
              <div class="d-flex justify-content-center align-items-center">
                <v-btn
                  style="position: sticky"
                  text
                  color="blue darken-4"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#multiCollapseVente' + index"
                  aria-expanded="false"
                  :aria-controls="'multiCollapseVente' + index"
                >
                  <span class="Poppins-Bold">{{
                    element.selected.length == 1
                      ? "1 site"
                      : `${element.selected.length} sites`
                  }}</span>
                  <v-icon class="ml-2" size="17"> mdi-plus </v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
          <tr class="collapse multi-collapse" :id="'multiCollapseVente' + index">
            <td id="subtd" colspan="8" class="padding-phone">
              <table width="100%" height="100%">
                <thead>
                  <tr>
                    <th style="text-align: center; width: 13vw; border: 2px black solid">
                      N° Compteur
                    </th>
                    <th style="text-align: center; width: 13vw; border: 2px black solid">
                      Début de contrat
                    </th>
                    <th style="text-align: center; width: 13vw; border: 2px black solid">
                      Durée de contrat
                    </th>
                    <th style="text-align: center; width: 13vw; border: 2px black solid">
                      Commentaire
                    </th>

                    <th style="text-align: center; width: 13vw; border: 2px black solid">
                      Valeur
                    </th>
                    <th style="text-align: center; width: 13vw; border: 2px black solid">
                      Statut
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, ind) in element.ventes">
                    <td class="td-table" id="subtd-1">
                      <div>
                        <span class="Poppins-Bold">{{ v.numCompteur }}</span>
                      </div>
                    </td>
                    <td class="td-table" id="subtd-2">
                      <div>
                        <span>{{ v.dateDebutFourniture }}</span>
                      </div>
                    </td>
                    <td class="td-table" id="subtd-3">
                      <div>
                        <span>{{ v.duree }} mois</span>
                      </div>
                    </td>
                    <td class="td-table" id="subtd-4">
                      <div class="mb-1">
                        <div v-if="v.status == 'Refuser'">
                          <v-btn icon small color="error" @click="openDialogComment(v)">
                            <v-icon dense>mdi-comment-eye-outline</v-icon>
                          </v-btn>
                        </div>
                        <div v-else>
                          <p class="ma-0">----</p>
                        </div>
                      </div>
                    </td>

                    <td class="td-table" id="subtd-5">
                      <div>
                        <div :id="'Value' + v.id">
                          <div class="d1">
                            <span class="Poppins-Bold">
                              {{
                                Number.isNaN(v.valeur)
                                  ? "0"
                                  : v.valeur.toLocaleString("fr-FR")
                              }}
                              €
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="td-table" id="subtd-6">
                      <div
                        class="d-flex flex-column justify-content-center align-items-center btn-group"
                      >
                        <small class="d-flex" v-if="v.status == 'Refuser'"
                          ><v-icon title="refusée" dense style="color: red"
                            >mdi-close-circle-outline</v-icon
                          ><span class="ml-2 Poppins-Bold">refusée</span></small
                        >
                        <small class="d-flex" v-if="v.status == 'Accepter'"
                          ><v-icon title="accepter" dense style="color: #00e676"
                            >mdi-check-circle-outline</v-icon
                          ><span class="ml-2 Poppins-Bold">acceptée</span></small
                        >
                        <small
                          class="d-flex justify-content-end"
                          style="width: 8rem"
                          v-if="v.status == 'en Attend'"
                          ><v-icon dense style="color: gray"
                            >mdi-timer-refresh-outline</v-icon
                          ><span class="ml-2 Poppins-Bold">en attente</span></small
                        >
                        <div
                          class="d-flex justify-content-end pa-0 ma-0"
                          v-if="v.status == 'en attente de validation'"
                        >
                          <v-icon dense color="blue darken-4"
                            >mdi-send-check-outline</v-icon
                          ><span class="ml-2 Poppins-Bold">en validation</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </template>
    </CustomDatatable>

    <div v-if="dialogChangeCommercial">
      <v-dialog v-model="dialogChangeCommercial" width="700">
        <v-card class>
          <v-toolbar :color="$colors[0]" class="text-white" dark>
            <span class="text-h6 pl-0">Changement Vendeur Vente</span>
          </v-toolbar>
          <v-card-text class="pa-4 mt-4">
            <v-text-field
              outlined
              dense
              disabled
              label="Vendeur Actuel"
              v-model="vente.Commercial"
            ></v-text-field>
            <v-select
              dense
              outlined
              label="Nouveau Vendeur"
              v-model="nouveauVendeur"
              :items="listeVendeurs"
              return-object
              item-text="username"
            ></v-select>
          </v-card-text>

          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              :color="$colors[0]"
              class="white--text"
              @click="changementVendeur(vente)"
            >
              Changer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="dialogComment">
      <v-dialog v-model="dialogComment" max-width="600px">
        <v-card>
          <v-toolbar :color="$colors[0]" dark>
            <v-card-title
              class="d-flex justify-content-center text-body-2 text-lg-h6 align-items-center"
              >{{
                ObjectComment?.commentaire
                  ? `Commentaire général de la vente`
                  : `Commentaire de refus de vente`
              }}</v-card-title
            >
          </v-toolbar>
          <v-card-text class="mt-8">
            <v-textarea
              class="text-body-2"
              readonly
              auto-grow
              outlined
              :value="
                ObjectComment?.commentaire
                  ? ObjectComment.commentaire
                  : ObjectComment?.commentaire_refus
              "
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions class="d-flex justify-content-end">
            <v-btn text color="error" @click="dialogComment = false"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <Snackbar
      :model="snackbarAPI.model"
      :message="snackbarAPI.message"
      :color="snackbarAPI.color"
    />
    <Loading
      class="pa-0"
      :dialogProps="loading.dialogLoading"
      :messageDialogeProps="loading.messageError"
      :loadingProps="loading.loadingValue"
      :errorProps="loading.errorValue"
      @closeDialog="closeDialog($event)"
    />
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar.vue";
import CustomDatatable from "@/components/datatable/CustomDatatable.vue";
import Pagination from "@/components/datatable/Pagination.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "VentesView",
  components: {
    CustomDatatable,
    Pagination,
    Snackbar,
    Loading,
  },
  data() {
    return {
      snackbarAPI: {
        message: "",
        color: "",
        model: false,
      },
      loading: {
        errorValue: false,
        loadingValue: false,
        messageError: "",
        dialogLoading: false,
      },
      dialogComment: false,
      vente: "",
      listeVentes: [],
      listeVentesCommercial: [],
      listeVendeurs: [],
      nouveauVendeur: "",

      dialogChangeCommercial: false,

      paginationObject: {},
      sortKey: "",
      sortOrders: "",
      sortBy: "",
      paginationVisible: true,

      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      columns: [
        { width: "13vw", label: "Date", name: "date", sortable: true },
        { width: "13vw", label: "Vendeur", name: "nom", sortable: true },
        { width: "13vw", label: "Raison Sociale", name: "raison", sortable: true },
        { width: "13vw", label: "Energie", name: "type", sortable: false },
        { width: "13vw", label: "Fournisseur", name: "fournisseur", sortable: false },
        { width: "13vw", label: "Fichiers", name: "Contrat", sortable: true },
        { width: "13vw", label: "Statut", name: "commentaire", sortable: false },
        { width: "13vw", label: "Multi-Site", name: "status", sortable: false },
      ],
      firstNonNullOrUndefinedIndex: -1,
      venteItems: [
        "Contrat",
        "RIB",
        "SEPA",
        "ADC",
        "CGV",
        "IBAN",
        "Factures",
        "EXTRAITPAPPERS",
      ],
    };
  },
  async mounted() {
    this.loading.dialogLoading = true;
    this.loading.loadingValue = true;
    await this.getAllVentesPro();
    await this.getAllVendeurs();
    this.loading.loadingValue = false;
    this.loading.dialogLoading = false;
  },
  methods: {
    openDialogComment(item) {
      (this.dialogComment = true), (this.ObjectComment = item);
    },
    countStatus(item) {
      var nbrEnAttend = 0;
      var nbrAccepter = 0;
      var nbrRefuser = 0;
      var nbrEnValidation = 0;
      for (var i = 0; i < item.ventes.length; i++) {
        if (item.ventes[i].status == "Refuser") {
          nbrRefuser = nbrRefuser + 1;
        } else if (item.ventes[i].status == "en Attend") {
          nbrEnAttend = nbrEnAttend + 1;
        } else if (item.ventes[i].status == "en attente de validation") {
          nbrEnValidation = nbrEnValidation + 1;
        } else if (item.ventes[i].status == "Accepter") {
          nbrAccepter = nbrAccepter + 1;
        }
      }
      return [nbrRefuser, nbrAccepter, nbrEnAttend, nbrEnValidation];
    },

    openDialogChangeCommercial(objetCotation) {
      this.dialogChangeCommercial = true;
      this.vente = objetCotation;
      this.nouveauVendeur = this.vente.Commercial;
      this.listeVendeurs[0] = this.nouveauVendeur;
    },
    async changementVendeur(vente) {
      this.loading.dialogLoading = true;
      this.loading.loadingValue = true;
      //  A TERMINER
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "changeVendeurOfVentPro/",
        method: "POST",
        data: {
          listeventepro_id: vente.id,
          vendeur_id: this.nouveauVendeur.id,
          token: this.$getToken("token"),
        },
      }).then((res) => {
        if (res.data.result) {
          this.snackbarAPI.color = "success";
        } else {
          this.snackbarAPI.color = "error";
        }
        this.loading.dialogLoading = false;
        this.loading.loadingValue = false;
        this.snackbarAPI.message = res.data.message;
        this.snackbarAPI.model = true;
      });

      this.dialogChangeCommercial = false;
      vente.Commercial = this.nouveauVendeur.username;
    },
    async rechercheData({ type, key }) {
      this.listeVentesCommercial = [];
      // PAGINATION PAS ENCORE GERER
      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.loading.dialogLoading = true;
        this.loading.loadingValue = true;
        this.tableData.length = key;
      }
      this.tableData.draw++;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "listVentesproDataTable/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: this.paginationObject.current_page,
          token: this.$getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.listeVentes = data.data.data;
              this.paginationObject = data.data;
              this.loading.loadingValue = false;
              this.loading.dialogLoading = false;
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async refreshData(pageNumber = 1) {
      this.loading.dialogLoading = true;
      this.loading.loadingValue = true;
      this.listeVentesCommercial = [];
      // PAGINATION PAS ENCORE GERER
      this.tableData.draw++;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "listVentesproDataTable/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.listeVentes = data.data.data;
              this.paginationObject = data.data;

              this.loading.dialogLoading = false;
              this.loading.loadingValue = false;
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async getAllVentesPro() {
      this.listeVentesCommercial = [];
      // A REMPLACER PLUS TARD AVEC LA NOUVELLE VIEW
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "listVentesproDataTable/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: 1,
          length: 10,
          token: this.$getToken("token"),
        },
      }).then((res) => {
        this.listeVentes = res.data.data.data.data;
        this.paginationObject = res.data.data.data;
      });
    },
    async getAllVendeurs() {
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllComptesForOptions/",
        method: "POST",
        data: {
          token: this.$getToken("token"),
        },
      }).then((res) => {
        this.listeVendeurs = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
.td-table {
  border: 2px black solid;
}
#table-liste-ventes th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}
.v-select.v-input--dense .v-select__selection--comma {
  color: white;
}
</style>
