import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import AppLayout from '@/layouts/AppLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

import CotationsView from '../views/CotationsView.vue'
import FournisseurView from "@/views/FournisseurView.vue"
import VentesView from "@/views/VentesView.vue"
import ComptesView from "@/views/ComptesView.vue"
import LoginView from "@/views/login/login.vue"
import PdlCompteurCsv from "@/views/PdlCompteurCsv.vue"
import scoreFromSiret from "@/views/ScoreFromSiret.vue"

Vue.use(VueRouter)

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/
const routes = [
  {
    path: '/login',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/LoginView.vue'),
      }
    ]
  },
  {
    path: '/cotations',
    alias:"/",
    name: 'Cotations',
    meta: {
      requiresAuth: true,
      roles: ["admin", "superadmin"],
      icon: "mdi-cash-multiple",
    },
    component: CotationsView
  },
  {
    path: '/ventes',
    name: 'Ventes',
    meta: {
      requiresAuth: true,
      roles: ["admin", "superadmin"],
      icon: "mdi-shopping-outline",
    },
    component: VentesView
  },
  {
    path: '/fournisseurs',
    name: 'Fournisseur',
    meta: {
      requiresAuth: true,
      roles: ["admin", "superadmin"],
      icon: "mdi-lightbulb-on-outline",
    },
    component: FournisseurView
  },
  {
    path: '/comptes',
    name: 'Comptes',
    meta: {
      requiresAuth: true,
      roles: ["admin", "superadmin"],
      icon: "mdi-account-group-outline",
    },
    component: ComptesView
  },
  {
    path: '/CsvFileForCompteur',
    name: 'Fichier CSV pour Compteur',
    meta: {
      requiresAuth: true,
      roles: ["admin", "superadmin"],
      icon: "mdi-account-group-outline",
    },
    component: PdlCompteurCsv
  },
  {
    path: '/ScoreFromSiret',
    name: 'Fichier CSV Score',
    meta: {
      requiresAuth: true,
      roles: ["admin", "superadmin"],
      icon: "mdi-account-group-outline",
    },
    component: scoreFromSiret
  },

  
]



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters.isAuthenticated; // pourrait etre remplacer par le token user
  const userRole = 'admin';

  if (requiresAuth) {
    console.log(localStorage.getItem('token'))
    if (!localStorage.getItem('token')) {
      // L'utilisateur n'est pas authentifié. Redirection vers la page de login.
      next('/login');
    } else if (userRole && localStorage.getItem('token') != null) {
      const requiredRoles = to.meta.roles;
      if (!requiredRoles || requiredRoles.includes(userRole)) {
        //L'utilisateur est authentifié et a le rôle approprié. Accès autorisé.
        next();
      } else {
        //L'utilisateur est authentifié mais n'a pas le rôle approprié. Redirection vers la page d'accueil.
        next('/');
      }
    }
  } else {
    // Pour les routes non protégées, laissez simplement passer
    next();
  }
});

export default router;

