import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/services/api'
import router from '@/router'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
  },
  mutations: {
    SET_USER(state, user) {
      const parsedUser = JSON.stringify(user);
      localStorage.setItem('user', parsedUser);
      state.user = user;
    },
    SET_TOKEN(state, token) {
      
        const data = {
            value: token,              
            ttl: Date.now() + ((8*60*60) * 1000),   
        }
    
      localStorage.setItem('token', JSON.stringify(data));
      state.token = token;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await api.post('login/', credentials);
        if (response.status == 200 && response.data.login == true) {
          const user = {
            nom: response.data.nom,
            prenom: response.data.prenom,
            email: response.data.email,
            role: response.data.type,
            phone: response.data.Telephone,
          };
          commit('SET_USER', user);
          commit('SET_TOKEN', response.data.token);
          router.push('/');
        }
      } catch (error) {
        // Handle authentication error.
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        commit('SET_USER', null);
        commit('SET_TOKEN', null);
      } catch (error) {
        // Handle logout error.
        throw error;
      }
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.token ? true : false;
    },
    getUser: (state) => {
      return state.user;
    },
    getToken: (state) => {
      return state.token;
    },
  },
  modules: {
  },
  plugins:[new VuexPersistence().plugin]
})
