<template>
  <v-app>
    <Drawer />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
import Drawer from "@/components/drawer/Drawer";
export default {
  name: "AppLayout",
  components: {
    Drawer
  }
}
</script>