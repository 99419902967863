<template>
    <div>
    <!-- breadCrumbs -->
    <div>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </div>
    <!-- breadCrumbs -->
    <!-- list compteurs form -->
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="elevation-2">
                    <v-card-title class="headline" primary-title>
                        <span class="headline">Liste des compteurs</span>
                    </v-card-title>
                    <v-card-text>
                        <form ref="form">
                            <v-text-field
                                v-model="pdlCompteurCsv"
                                label="PDL"
                                required
                            />
                            <v-btn @click="getPdlCompteurCsv" color="primary">Rechercher</v-btn>

                        </form>
                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <Loading
      class="pa-0"
      :dialogProps="dialogLoadingCotation"
      :messageDialogeProps="messageErrorCotation"
      :loadingProps="loadingCotation"
      :errorProps="errorCotation"
      @closeDialog="closeDialog($event)"
    />
</div>
</template>
<script>
import axios from 'axios'
import Loading from "@/components/Loading.vue";
export default {
  name: 'PdlCompteurCsv',
  components: {
    Loading
  },
  data() {
    return {
        pdlCompteurCsv: '',
        errorCotation: false,
        loadingCotation: false,
        messageErrorCotation: "",
        dialogLoadingCotation: false,
        fileCsvLink : '',
        items: [
            {
              text: 'Home',
              disabled: false,
              href: 'dashboard',
            },
            {
              text: 'PdlCompteurCsv',
              
            },
          ],


    }
      
  },
 methods: {
    async getPdlCompteurCsv() {
      this.dialogLoadingCotation = true;
      this.loadingCotation = true;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "createCsvFileForCompteur/",
        method: "POST",
        data: {
            token: this.$store.state.token,
            listCompteur: this.pdlCompteurCsv,
        },
      })
        .then((res) => {
          this.fileCsvLink = process.env.VUE_APP_URL_API_SERVER + res.data.file;
          console.log(process.env.VUE_APP_URL_API_SERVER + res.data.file)
          this.loadingCotation = false;
          this.dialogLoadingCotation = false;
          // download file
          const link = document.createElement('a');
          link.href = this.fileCsvLink;
          link.setAttribute('download', `compteur_${this.pdlCompteurCsv}.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
         
        })
        .catch((error) => {
          this.loadingCotation = false;
          this.errorCotation = true;
          this.messageErrorCotation = error;
        });
  }
},
}

</script>

<style scoped>
</style>