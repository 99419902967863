<template>
  <div class="ma-4">
    <v-dialog v-model="openModalAjoutFournisseur" width="500">
      <v-card>
        <v-card-title class="ma-0 pa-0">
          <v-toolbar height="50" :color="$colors[0]" class="text-white" dark>
            <span>Ajouter un fournisseur</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-select
            v-model="newFournisseur"
            dense
            outlined
            hide-details="auto"
            :items="getNewFournisseur"
          >
          </v-select>
          <v-btn
            @click="ajouterFournisseurTableau(newFournisseur)"
            :colors="$colors[0]"
            class="mt-4 white--text"
            style="width: 100%"
            :color="$colors[0]"
            >Créer</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div style="position: relative">
      <v-btn
        @click="ajouterFournisseur()"
        style="position: absolute; right: 0px"
        elevation="0"
        outlined
        :color="$colors[0]"
        >Ajouter un fournisseur
      </v-btn>
      <CustomDatatable
        id="table-validation-cotation"
        :isPaginated="true"
        :columns="columns"
        :sortKey="sortKey"
        :sortOrders="sortOrders"
        @sort="sortDataTable($event)"
        @search="rechercheData"
        @refreshData="refreshData($event)"
        @rechercheData="rechercheData($event)"
        :paginationObjectProps="paginationObject"
      >
        <template v-slot:body>
          <tbody class="text-center" v-if="itemsDataTable.length > 0">
            <tr v-for="(fournisseur, index) in itemsDataTable">
              <td>
                <div style="max-width: 100%" class="d-flex flex-row justify-center mt-2">
                  <v-img
                    max-height="50px"
                    contain
                    :src="getImageLogo(fournisseur.fournisseur)"
                  ></v-img>
                </div>
              </td>
              <td style="vertical-align: middle">
                <div class="d-flex flex-row justify-center">
                  <input
                    @click="updateEtatFournisseur(fournisseur.id, !fournisseur.etat)"
                    type="checkbox"
                    :id="'checkbox' + index"
                    class="checkbox"
                    v-model="fournisseur.etat"
                  />
                  <label :for="'checkbox' + index" class="switch">
                    {{ fournisseur.etat ? "Activé" : "Désactivé" }}
                    <svg
                      class="slider"
                      viewBox="0 0 512 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"
                      ></path>
                    </svg>
                  </label>
                </div>
              </td>
            </tr></tbody
        ></template>
      </CustomDatatable>
      <Snackbar
        :model="snackbarAPI.model"
        :message="snackbarAPI.message"
        :color="snackbarAPI.color"
      />
      <Loading
        class="pa-0"
        :dialogProps="dialogLoadingCotation"
        :messageDialogeProps="messageErrorCotation"
        :loadingProps="loadingCotation"
        :errorProps="errorCotation"
        @closeDialog="closeDialog($event)"
      />
    </div>
  </div>
</template>
<script>
import CustomDatatable from "@/components/datatable/CustomDatatable.vue";
import Snackbar from "@/components/Snackbar.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "FournisseurView",
  components: {
    CustomDatatable,
    Snackbar,
    Loading,
  },
  data() {
    return {
      errorCotation: false,
      loadingCotation: false,
      messageErrorCotation: "",
      dialogLoadingCotation: false,
      snackbarAPI: {
        message: "",
        color: "",
        model: false,
      },
      newFournisseur: "",
      openModalAjoutFournisseur: false,
      sortKey: "",
      sortOrders: "",
      sortBy: "fournisseur",
      paginationVisible: true,

      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "asc",
      },
      paginationObject: {},
      columns: [
        { width: "50%", label: "Fournisseur", name: "fournisseur", sortable: true },
        { width: "50%", label: "Statut", name: "etat", sortable: true },
      ],
      listeTotalFournisseur: [
        "Alpiq",
        "Alterna",
        "Antargaz",
        "Axpo",
        "BARRY",
        "BULB",
        "BUTAGAZ",
        "CDISCOUNT",
        "Dyneff",
        "Edenkia",
        "EDF",
        "EDS",
        "EDSB",
        "Ekwateur",
        "Elecocite",
        "Electricite_de_provence",
        "Electricite_de_savoie",
        "Electricite_de_Strasbourg",
        "ELMY",
        "Enalp",
        "Endesa",
        "Enercoop",
        "Energem",
        "ENERGIE_DICI",
        "Engie",
        "ENI",
        "Enovos",
        "Gaz_de_Bordeaux",
        "GazelEnergie",
        "Gazprom",
        "GEDIA",
        "GEG",
        "GREEN_YELLOW",
        "HAPPE",
        "IBERDROLA",
        "ILEK",
        "LECLERCQ",
        "LLUM",
        "LUCIA",
        "MEGA",
        "MET-France",
        "mint-energie",
        "NatGas",
        "OHM",
        "OVO",
        "Picoty",
        "PLUM",
        "Primeo",
        "Proxelia",
        "Redeéo",
        "SaveEnergies",
        "Selia",
        "SEFE",
        "SOWEE",
        "Synelva",
        "Total",
        "Urban_Solar",
        "Vattenfall",
        "Volterres",
        "Wekiwi",
        "yeli-xs",
      ],
      itemsDataTable: [],
    };
  },
  computed: {
    getNewFournisseur() {
      const labelArray = this.itemsDataTable.map((obj) => obj.fournisseur);
      return this.listeTotalFournisseur.filter((el) => !labelArray.includes(el));
    },
  },
  async created() {
    await this.getAllFournisseur();
  },
  methods: {
    async sortDataTable(event) {
      if (this.tableData["dir"] == "desc") {
        this.tableData["dir"] = "asc";
      } else {
        this.tableData["dir"] = "desc";
      }
      this.sortBy = event;
      console.log();
      await this.getAllFournisseur();
    },
    async refreshData(pageNumber = 1) {
      this.tableData.draw++;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllFournisseurs/",
        method: "POST",
        data: {
          liste_fournisseurs: this.listeTotalFournisseur,
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$getToken("token"),
          sortBy: this.sortBy,
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.itemsDataTable = [...data.data.data];
              this.paginationObject = data.data;
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
          this.loadingCotation = false;
          this.errorCotation = true;
          this.messageErrorCotation = errors;
        });
    },
    async updateEtatFournisseur(id_fournisseur, new_etat_fournisseur) {
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "updateFournisseurEtat/",
        method: "POST",
        data: {
          id_fournisseur: id_fournisseur,
          etat_fournisseur: new_etat_fournisseur,
          token: this.$getToken("token"),
          sortBy: this.sortBy,
        },
      }).then((res) => {
        console.log(res);
      });
    },
    async getAllFournisseur() {
      this.dialogLoadingCotation = true;
      this.loadingCotation = true;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllFournisseurs/",
        method: "POST",
        data: {
          liste_fournisseurs: this.listeTotalFournisseur,
          dataTable: this.tableData,
          pageNumber: 1,
          token: this.$getToken("token"),
          sortBy: this.sortBy,
        },
      })
        .then((res) => {
          console.log(res.data.data);
          this.itemsDataTable = [...res.data.data.data.data];
          this.paginationObject = res.data.data.data;
        })
        .catch((err) => {
          this.loadingCotation = false;
          (this.errorCotation = true), (this.messageErrorCotation = err);
        });
      this.dialogLoadingCotation = false;
      this.loadingCotation = false;
    },
    async ajouterFournisseurTableau(newFournisseur) {
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "addFournisseur/",
        method: "POST",
        data: {
          fournisseur_nom: newFournisseur,
          token: this.$getToken("token"),
        },
      }).then(async (res) => {
        console.log(res.data);
        await this.getAllFournisseur();
      });
    },
    ajouterFournisseur() {
      this.openModalAjoutFournisseur = true;
    },
    getImageLogo(logo) {
      return require(`@/assets/logoParticulier/fournisseur_logos/${logo}_120X35.png`);
    },
    async rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      this.tableData.draw++;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllFournisseurs/",
        method: "POST",
        data: {
          liste_fournisseurs: this.listeTotalFournisseur,
          dataTable: this.tableData,
          pageNumber: this.paginationObject.current_page,
          token: this.$getToken("token"),
          sortBy: this.sortBy,
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.itemsDataTable = data.data.data;
              this.paginationObject = data.data;
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<style scoped>
.checkbox {
  display: none;
}

.switch {
  position: relative;
  width: 136px;
  padding: 10px 20px;
  background-color: #ff1744;
  border-radius: 5px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  font-size: 0.9em;
  font-weight: 600;
  transition: all 0.3s;
}
.switch svg path {
  fill: white;
}
.checkbox:checked + .switch {
  background-color: #00e676;
}
</style>
