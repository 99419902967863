<template>
  <v-app>
    <Drawer />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Drawer from "./components/drawer/Drawer.vue"
export default {
  name: "App",
  components: {
    Drawer,
  },
};
</script>

<style>
@import "./css/base.css";
@font-face {
  font-family: "Poppins-Regular";
  src: url("assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("assets/fonts/Poppins-Bold.ttf") format("truetype");
}
.Poppins-Bold {
  font-family: "Poppins-Bold";
}
.Poppins-Regular {
  font-family: "Poppins-Regular";
}
* {
  font-family: "Poppins-Regular";
}
</style>
