import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify/lib'

import axios from "axios"

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";


Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuetify)
Vue.config.productionTip = false
Vue.prototype.$store = store;
Vue.prototype.$axios = axios
Vue.prototype.$colors = ["#01579B", "#1E6CA4","#FBC02D","#fab627"];
Vue.prototype.$styles = {
  background: "#007991 linear-gradient(45deg, #007991 0%, #18aea2 68%)",
};
Vue.prototype.$getToken = (keyName) => {
  const data = localStorage.getItem(keyName);
  if (!data) {
    router.push('/login');
    return null;
  }
  const item = JSON.parse(data);
  if (Date.now() > item.ttl) {
      localStorage.removeItem(keyName);
      router.push('/login');
      return null;
  }
  return item.value;
}
Vue.prototype.$setToken = (keyName, keyValue, ttl) => {
  const data = {
      value: keyValue,              
      ttl: Date.now() + (ttl * 1000),   
  }

  localStorage.setItem(keyName, JSON.stringify(data));
}

Vue.prototype.$rules = {
  emailRules:v => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || 'E-mail invalide', 
  matchingPassword(nouveauMDP,confirmationMDP) {
    return nouveauMDP === confirmationMDP || "mot de passe différent !"
  },
  required(value){
    return !!value || 'Champ obligatoire !'
  }
}
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
